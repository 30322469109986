import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'

export function toDraft(html: string) {
  const blocksFromHTML = convertFromHTML(html)
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  )
  return EditorState.createWithContent(contentState)
}

export function toHTML(draft: EditorState) {
  return draftToHtml(convertToRaw(draft.getCurrentContent())).replace(/"/g, `'`)
}
