import { useEffect } from 'react'
import { Link, useRouteError, useLocation } from 'react-router'
import * as Sentry from '@sentry/react'
import { isRouteErrorResponse } from 'react-router'

export default function ErrorPage() {
  const error = useRouteError()
  const location = useLocation()

  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  if (isRouteErrorResponse(error) && error.status === 404) {
    const pathname = location.pathname

    window.location.href = `https://listen.jewishpodcasts.fm${pathname}`
    return 'Redirecting...'
  }

  return (
    <>
      <main className="grid min-h-full px-6 py-24 bg-white place-items-center sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-indigo-600">Ouch!</p>
          <h1 className="mt-4 text-5xl font-semibold tracking-tight text-gray-900 text-balance sm:text-7xl">
            We got an error.
          </h1>
          <p className="mt-6 text-lg font-medium text-gray-500 text-pretty sm:text-xl/8">
            Sorry, seems like something went wrong. If the issue persists,
            please contact support.
          </p>
          <div className="flex items-center justify-center mt-10 gap-x-6">
            <Link
              to="/shows"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              My Shows
            </Link>
            <Link to="/" className="text-sm font-semibold text-gray-900">
              Go to home <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </div>
      </main>
    </>
  )
}
