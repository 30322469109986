import { Outlet } from 'react-router'
import { Helmet } from 'react-helmet-async'
import { UploadProvider } from './context/UploadContext'
import { UserProvider } from './context/UserContext'
import { useEffect } from 'react'
import axios from 'axios'
import { Toaster } from 'sonner'
import { ClerkProvider, useAuth } from '@clerk/react-router'

import { useUser } from '@/context/UserContext'

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY

import { Crisp } from 'crisp-sdk-web'

Crisp.configure('b737d1a9-9aa7-4fbb-8c13-9d568df11c44', {
  autoload: false
})

function App() {
  const { getToken } = useAuth()
  const { user } = useUser()

  axios.interceptors.request.use(async config => {
    const token = await getToken()

    config.headers.Authorization =
      config.headers.Authorization || `Bearer ${token}`

    config.baseURL = config.baseURL || import.meta.env.VITE_API_URI

    return config
  })

  useEffect(() => {
    if (user) {
      Crisp.user.setEmail(user.email)
      Crisp.user.setNickname(user.firstname + ' ' + user.lastname)
    } else {
      Crisp.session.reset()
    }
  }, [user])

  useEffect(() => {
    setTimeout(() => {
      Crisp.load()
    }, 3000)
  })

  return (
    <>
      <div className="min-h-screen">
        <Outlet />
      </div>
      <footer className="bg-white">
        <div className="px-6 py-12 mx-auto max-w-7xl md:flex md:items-center md:justify-between lg:px-8">
          <p className="mt-8 text-center text-gray-600 text-sm/6 md:order-1 md:mt-0">
            &copy; 2024 Jewish Podcast LLC. All rights reserved.
          </p>
        </div>
      </footer>
    </>
  )
}

export const RootLayout = () => (
  <>
    <Helmet>
      <title>Jewish Podcasts Dashboard</title>
      <link rel="icon" href="/favicon.ico" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta property="og:title" content="Jewish Podcasts Dashboard" />
      <meta
        property="og:description"
        content="Start You Own Free Jewish Podcast Today"
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={import.meta.env.VITE_URL} />
      <meta property="og:title" content="Jewish Podcasts Dashboard" />
      <meta
        property="og:description"
        content="Start your own Jewishh Podcast today"
      />
      <meta
        property="og:image"
        content="https://jewishpodcasts-prod.s3.amazonaws.com/default_image.png"
      />
    </Helmet>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <UserProvider>
        <Toaster position="top-center" />
        <UploadProvider>
          <App />
        </UploadProvider>
      </UserProvider>
    </ClerkProvider>
  </>
)
