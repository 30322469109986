import { createContext, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import useSWR from 'swr'
import { useAuth } from '@clerk/react-router'

type AuthUser = {
  id: string
  email: string
  role: string
  firstname: string
  lastname: string
}

const UserContext = createContext<{
  user: AuthUser | undefined
  isLoading: boolean
  isAdmin: boolean
} | null>(null)

export const useUser = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { isLoaded, userId, isSignedIn } = useAuth()
  const [isAdmin, setIsAdmin] = useState(false)

  const {
    data: user,
    mutate,
    isLoading
  } = useSWR<AuthUser | undefined>(
    isSignedIn ? 'me' : null,
    () =>
      isSignedIn
        ? axios
            .get(import.meta.env.VITE_API_URI + '/v3/users/me')
            .then(res => res.data)
        : undefined,
    {
      fallbackData: undefined
    }
  )

  useEffect(() => {
    mutate()
  }, [isLoaded, userId, isSignedIn])

  useEffect(() => {
    if (user) {
      setIsAdmin(user.role === 'ADMIN')
    } else {
      setIsAdmin(false)
    }
  }, [user])

  return (
    <UserContext.Provider
      value={{
        user,
        isLoading,
        isAdmin
      }}>
      {children}
    </UserContext.Provider>
  )
}
