import axios from 'axios'
import * as Sentry from '@sentry/react'
import { toast } from 'sonner'

// prevents axios from adding Authorization header
const audioRequest = axios.create()

export async function uploadImage(file: File) {
  const res = await axios
    .put<{ location: string }>('/imageupload', file, {
      headers: {
        'Content-Type': file.type
      }
    })
    .then(res => res.data)
  return res.location
}

export async function uploadAudio(file: File, toastId: string | number) {
  Sentry.addBreadcrumb({
    category: 'uploadAudio',
    message: 'Uploading audio file',
    level: 'info'
  })
  const { url, location } = await axios
    .get('/upload', {
      params: {
        filename: file.name
      }
    })
    .then(res => res?.data)
    .catch(err => {
      Sentry.captureException(err)
    })

  if (!url) return
  Sentry.addBreadcrumb({
    category: 'uploadAudio',
    message: 'Uploading audio file to S3',
    data: { url, location, size: file.size },
    level: 'info'
  })

  await audioRequest
    .put(url, file, {
      headers: {
        Accept: 'application/json',
        Authorization: undefined
      },
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total!
        )
        toast.loading(`Uploading audio... ${percentCompleted}%`, {
          id: toastId
        })
      },
      timeout: 300000,
      timeoutErrorMessage: 'Request took longer than 5 minutes'
    })
    .catch(err => {
      Sentry.captureException(err)
      throw new Error("Couldn't upload file")
    })

  return [location, file.size, location]
}
