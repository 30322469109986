import { createContext, useContext } from 'react'
import { EditorState } from 'draft-js'
import { toast } from 'sonner'
import { useParams, useNavigate, useSearchParams } from 'react-router'
import { uploadImage, uploadAudio } from '@/utils/uploadMedia'
import { toHTML } from '@/utils/draft'
import axios from 'axios'
import { useSWRConfig } from 'swr'

type EpisodeValue = {
  title: string
  description: EditorState
  publishDate: Date
  season: number
  tags: number[]
  artwork: string
  audio: string
  status: string
}

const UploadContext = createContext<{
  submitEpisode: (values: EpisodeValue) => Promise<void>
} | null>(null)

export const useUpload = () => {
  const context = useContext(UploadContext)
  if (!context) {
    throw new Error('useUpload must be used within a UploadContext')
  }
  return context
}

export const UploadProvider = ({ children }: { children: React.ReactNode }) => {
  const { id, showid } = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { mutate } = useSWRConfig()

  const submitEpisode = async (values: EpisodeValue) => {
    if (!showid) throw new Error('Show ID is required')

    const loadingToast = toast.loading('Preparing to upload episode...', {
      richColors: true,
      position: 'bottom-right'
    })

    navigate(`/shows/${showid}`)

    const audio = values.audio as string | File
    const artwork = values.artwork as string | File

    try {
      if (artwork instanceof File) {
        if (artwork.size > 5 * 1024 * 1024) {
          throw new Error('Image size must be less than 5MB')
        }

        toast.loading('Uploading artwork...', {
          id: loadingToast
        })
        values.artwork = await uploadImage(artwork)
      }

      if (audio instanceof File) {
        toast.loading('Uploading audio...', {
          id: loadingToast
        })
        const audioUpload = await uploadAudio(audio, loadingToast)
        if (!audioUpload) return
        values.audio = audioUpload[0]
        // @ts-expect-error size is not a valid property
        values.size = audioUpload[1]
      }

      toast.loading(id ? 'Updating episode...' : 'Creating episode...', {
        id: loadingToast
      })

      const formData = {
        ...values,
        description: toHTML(values.description)
      }

      if (id) {
        await axios.put(
          `/v3/shows/${showid}/episodes/${id}`,
          formData
        )
      } else {
        await axios.post(
          `/v3/shows/${showid}/episodes`,
          formData
        )
      }

      toast.success(id ? 'Episode updated' : 'Episode created', {
        id: loadingToast
      })

      const search = searchParams.get('q')
      const page = Number(searchParams.get('page')) || 1

      mutate(['episodes', showid, page, search])

      // mutate
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message, {
          id: loadingToast
        })
      } else {
        toast.error('Failed to upload episode', {
          id: loadingToast
        })
      }
    }
  }
  return (
    <UploadContext.Provider
      value={{
        submitEpisode
      }}>
      {children}
    </UploadContext.Provider>
  )
}
