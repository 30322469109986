import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from '@headlessui/react'
import { Bars3Icon, XMarkIcon, UserIcon } from '@heroicons/react/24/outline'
import { Link, useLocation } from 'react-router'
import { twMerge } from 'tailwind-merge'
import { useClerk } from '@clerk/react-router'
import { useUser } from '../context/UserContext'
import { useEffect, useState } from 'react'

const defaultLinks = [
  {
    title: 'Dashboard',
    href: '/shows'
  },
  {
    title: 'Billing',
    href: '/billing'
  }
]

export function Navbar() {
  const location = useLocation()
  const { signOut } = useClerk()
  const [links, setLinks] = useState(defaultLinks)
  const { user } = useUser()

  const path = location.pathname

  useEffect(() => {
    if (user?.role === 'ADMIN') {
      const newLinks = [...defaultLinks, { title: 'Admin', href: '/admin' }]
      setLinks(newLinks)
      return
    }
    if (links[links.length - 1].title === 'Admin') {
      setLinks(defaultLinks)
    }
  }, [user])

  return (
    <Disclosure as="nav" className="mb-8 bg-white shadow">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex items-center mr-2 -ml-2 md:hidden">
              {/* Mobile menu button */}
              <DisclosureButton className="relative inline-flex items-center justify-center p-2 text-gray-400 rounded-md group hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open main menu</span>
                <Bars3Icon
                  aria-hidden="true"
                  className="block size-6 group-data-[open]:hidden"
                />
                <XMarkIcon
                  aria-hidden="true"
                  className="hidden size-6 group-data-[open]:block"
                />
              </DisclosureButton>
            </div>
            <Link to="/" className="flex items-center shrink-0">
              <img
                alt="Jewish Podcasts"
                src="/favicon.ico"
                className="w-auto h-8"
              />
            </Link>
            <div className="hidden md:ml-6 md:flex md:space-x-8">
              {links.map(link => (
                <Link
                  key={link.href}
                  to={link.href}
                  className={twMerge(
                    'inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 border-b-2 border-transparent hover:border-gray-300',
                    path === link.href && 'border-indigo-500 text-gray-900'
                  )}>
                  {link.title}
                </Link>
              ))}
            </div>
          </div>
          <div className="flex items-center">
            {/* <div className="shrink-0">
              <button
                type="button"
                className="relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <PlusIcon aria-hidden="true" className="-ml-0.5 size-5" />
                New Episode
              </button>
            </div> */}
            <div className="hidden md:ml-4 md:flex md:shrink-0 md:items-center">
              {/* Profile dropdown */}
              <Menu as="div" className="relative ml-3">
                <div>
                  <MenuButton className="relative flex p-2 text-sm bg-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open user menu</span>
                    <UserIcon className="w-6" />
                  </MenuButton>
                </div>
                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                  <MenuItem>
                    <button
                      onClick={() => signOut()}
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:outline-none">
                      Sign out
                    </button>
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
          </div>
        </div>
      </div>

      <DisclosurePanel className="md:hidden">
        <div className="pt-2 pb-3 space-y-1">
          {links.map(link => (
            <DisclosureButton
              key={link.href}
              as={Link}
              to={link.href}
              className={twMerge(
                'block py-2 pl-3 pr-4 text-base font-medium border-l-4   sm:pl-5 sm:pr-6',
                path === link.href && 'border-indigo-500 bg-indigo-50 '
              )}>
              {link.title}
            </DisclosureButton>
          ))}
        </div>
        <div className="pt-4 pb-3 border-t border-gray-200">
          <div className="flex items-center px-4 sm:px-6">
            <div className="ml-3">
              <div className="text-base font-medium text-gray-800">
                {user?.firstname} {user?.lastname}
              </div>
              <div className="text-sm font-medium text-gray-500">
                {user?.email}
              </div>
            </div>
          </div>
          <div className="mt-3 space-y-1">
            <DisclosureButton
              onClick={() => signOut()}
              className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6">
              Sign out
            </DisclosureButton>
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
  )
}
