import { useEffect } from 'react'
import {
  createBrowserRouter,
  Outlet,
  Navigate,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router'
import { RootLayout } from './RootLayout'
import { LimitedContainer } from '@/components/ui/container'
import { Navbar } from '@/components/Navbar'
import ErrorPage from './routes/Error'
import { Protect, useAuth } from '@clerk/clerk-react'
import { useUser } from '@/context/UserContext'

import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN!,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    })
  ],
  tracesSampleRate: 0.8,
  profilesSampleRate: 1.0,
  beforeSend: event => {
    const error = event.exception?.values?.[0]?.value ?? null
    if (!error) return null
    if (error.startsWith('No route matches URL')) return null
    if (error === 'Request failed with status code 401') return null

    return event
  }
})

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV6(createBrowserRouter)

export const router = sentryCreateBrowserRouter([
  {
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        lazy: () => import('./routes/Home')
      },
      {
        path: '/audio/are-your-hands-full',
        lazy: () => import('./routes/Book')
      },

      // Auth
      {
        Component: () => {
          const { isSignedIn } = useAuth()
          if (isSignedIn) return <Navigate to="/shows" />
          return <Outlet />
        },
        children: [
          {
            path: '/login',
            lazy: () => import('./routes/auth/Login')
          },
          {
            path: '/logout',
            lazy: () => import('./components/utils/Logout')
          },
          {
            path: '/signup',
            lazy: () => import('./routes/auth/Register')
          },
          {
            path: '/register',
            lazy: () => import('./routes/auth/Register')
          },
          {
            path: '/forgot-password',
            lazy: () => import('./routes/auth/ForgotPassword')
          }
        ]
      },
      // User
      {
        Component: () => (
          <Protect fallback={<Navigate to="/login" />}>
            <Navbar />
            <LimitedContainer>
              <Outlet />
            </LimitedContainer>
          </Protect>
        ),
        children: [
          {
            path: '/shows',
            children: [
              {
                index: true,
                lazy: () => import('./routes/shows/Shows')
              },
              {
                path: 'create',
                lazy: () => import('./routes/shows/Create')
              },
              {
                path: ':showid',
                id: 'show-layout',
                lazy: () => import('./routes/shows/ShowLayout'),
                children: [
                  {
                    index: true,
                    lazy: () => import('./routes/shows/Episodes')
                  },
                  {
                    path: 'analytics',
                    id: 'analytics-layout',
                    lazy: () => import('./routes/shows/AnalyticsLayout'),
                    children: [
                      {
                        index: true,
                        lazy: () => import('./routes/shows/Analytics')
                      }
                    ]
                  },
                  {
                    path: 'edit',
                    lazy: () => import('./routes/shows/Edit')
                  },
                  {
                    path: 'distribution',
                    lazy: () => import('./routes/shows/Distribution')
                  },
                  {
                    path: 'emails',
                    lazy: () => import('./routes/shows/Emails')
                  },
                  {
                    path: 'settings',
                    lazy: () => import('./routes/shows/Settings')
                  },
                  {
                    path: 'episodes/create',
                    lazy: () => import('./routes/shows/CreateEpisode')
                  },
                  {
                    path: 'episodes/:id',
                    lazy: () => import('./routes/shows/EditEpisode')
                  }
                ]
              }
            ]
          },
          {
            path: '/billing',
            lazy: () => import('./routes/Billing')
          }
        ]
      },
      // Admin
      {
        Component: () => {
          const { user, isLoading } = useUser()
          if (isLoading) return null
          return (
            <Protect fallback={<Navigate to="/login" />}>
              {user?.role === 'ADMIN' ? <Outlet /> : <Navigate to="/shows" />}
            </Protect>
          )
        },
        children: [
          {
            path: '/admin',
            lazy: () => import('./routes/admin/AdminLayout'),
            children: [
              { index: true, lazy: () => import('./routes/admin/Shows') },
              {
                path: 'accounts',
                lazy: () => import('./routes/admin/Acounts')
              },
              {
                path: 'analytics',
                lazy: () => import('./routes/admin/Analytics')
              }
            ]
          },
          {
            path: '/admin/shows/:showid',
            lazy: () => import('./routes/admin/EditShow')
          },
          {
            path: '/admin/users/:userid',
            lazy: () => import('./routes/admin/EditUser')
          }
        ]
      }
    ]
  }
])
